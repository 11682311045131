<template>
  <div>
    <div class="vaults">
      <div
        class="hfi-vaults-table-header uk-flex uk-flex-between uk-flex-middle uk-flex-wrap"
      >
        <h4
          class="uk-flex uk-flex-middle uk-margin-remove-bottom"
          uk-tooltip="title: shows vault fxToken, collateral &amp; platform details; pos: right"
        >
          <img
            height="20"
            uk-svg
            class="hfi-svg uk-margin-small-right"
            src="/safe.svg"
            alt="vaults"
          />
          vaults
        </h4>
        <span v-if="!loading && sdk && account && !showCollateralDetails">
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: borrow fxTokens against your collateral; pos: right;"
            @click="openBorrowModal"
          >
            borrow
          </a>
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: repay borrowed fxTokens; pos: right;"
            @click="openRepayModal"
          >
            repay
          </a>
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: deposit collateral to a current/new fxToken vault; pos: right;"
            @click="openDepositCollateral"
          >
            deposit collateral
          </a>
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: withdraw collateral from a current fxToken vault; pos: right;"
            @click="openWithdrawCollateral"
          >
            withdraw collateral
          </a>
        </span>
      </div>

      <table
        class="uk-table uk-table-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
      >
        <thead>
          <tr>
            <th scope="col" rowspan="2" class="uk-text-top">
              <span
                class="cursor-pointer"
                uk-tooltip="title: click on a chevron against a vault to view the collateral breakdown; pos: right"
              >
                vault
              </span>
            </th>

            <th
              scope="col"
              colspan="5"
              class="uk-text-center hfi-table-border-left"
            >
              debt
            </th>

            <th
              scope="col"
              colspan="6"
              class="uk-text-center hfi-table-border-left"
            >
              collateral
            </th>
          </tr>

          <tr>
            <th scope="col" class="uk-text-right hfi-table-border-left">
              <span
                class="cursor-pointer"
                uk-tooltip="title: current debt based upon amount of tokens already minted; pos: right"
              >
                current
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: total debt available for minting up to minimum collateral ratio; pos: right"
              >
                limit
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: amount of debt available to mint up to minimum collateral ratio; pos: right"
              >
                available
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: maximum minting ratio - minting limit as a percentage of total vault collateral value; pos: right"
              >
                MMR
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: utilisation - total vault minted value as a percentage of minting limit; pos: right"
              >
                util.
              </span>
            </th>

            <th scope="col" class="uk-text-right hfi-table-border-left">
              <span
                class="cursor-pointer"
                uk-tooltip="title: vault total collateral value in vault currency; pos: right"
              >
                value
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: vault collateral ratio - current vault collateral value as a percentage of minted value; pos: right"
              >
                CR
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: minimum vault collateral ratio - weighted mean of each deposited collateral minimum collateral value; pos: right"
              >
                min. CR
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: weighted mean interest rate for all collateral for the vault; pos: right"
              >
                int. rate
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: weighted mean liquidation fee for all collateral for the vault; pos: right"
              >
                liq. fee
              </span>
            </th>

            <th scope="col" class="uk-text-right">
              <span
                class="cursor-pointer"
                uk-tooltip="title: CR at which vault collateral will begin to be liquidated by keeper pools; pos: right"
              >
                liq. trigger
              </span>
            </th>
          </tr>
        </thead>

        <tbody v-if="loading">
          <tr>
            <td colspan="4">
              <vue-loaders-ball-pulse
                color="currentColor"
                scale="0.5"
                class="uk-text-middle"
                style="margin-top: -3px"
              />
            </td>
          </tr>
        </tbody>

        <tbody
          v-else-if="
            !displayVaults.some(
              (vault) =>
                vault.debt.gt(0) ||
                (vault.collateralValue && vault.collateralValue.gt(0))
            )
          "
        >
          <tr>
            <td colspan="4">
              {{
                this.account
                  ? "no vaults for this account. deposit to create one."
                  : "no wallet connected"
              }}
            </td>
          </tr>
        </tbody>

        <tbody
          v-else-if="!loading"
          v-for="(vault, ix) in displayVaults.filter(
            (vault) =>
              vault.debt.gt(0) ||
              (vault.collateralValue && vault.collateralValue.gt(0))
          )"
          :key="fxTokenKey + '-' + ix"
        >
          <tr
            :class="`${vault.status ? 'hfi-' + vault.status : ''} ${
              vault.token.symbol === showCollateralDetails
                ? 'row-highlight'
                : ''
            }`"
          >
            <!-- Token -->
            <td
              scope="row"
              data-label="vault"
              class="uk-text-nowrap hfi-token-cell uk-text-right"
            >
              <a
                v-if="
                  vault.collateral.filter((collateral) =>
                    collateral.amount.gt(0)
                  ).length > 0
                "
                @click="toggleCollateralDetails(vault.token.symbol)"
                class="uk-margin-auto-top hfi-vaults-open-button"
                :uk-tooltip="`title: ${
                  showCollateralDetails === vault.token.symbol ? 'hide' : 'show'
                } ${vault.token.symbol} collateral details; pos: right`"
              >
                <i
                  v-if="showCollateralDetails === vault.token.symbol"
                  class="fal fa-minus-square"
                ></i>
                <i v-else class="fal fa-plus-square"></i>
              </a>

              <img
                class="uk-margin-small-left hfi-margin-top-n2"
                width="22"
                :src="`${vault.token.symbol}Logo.png`"
              />

              {{ vault.token.symbol }}
              <a
                class="hfi-vaults-icon-button"
                :href="
                  vault && vault.explorerMeta ? vault.explorerMeta.typeUrl : ''
                "
                target="_blank"
                :uk-tooltip="`title: ${
                  vault.explorerMeta
                    ? vault.explorerMeta.typeMessage
                    : undefined
                }; pos: right;`"
              >
                <i class="fal fa-external-link-square"></i>
              </a>
            </td>

            <!-- Minting columns -->
            <td
              data-label="current debt"
              class="uk-text-right hfi-table-border-left"
            >
              <!-- Vault minting amount -->
              {{ formatEther(vault.debt) }}
            </td>

            <td data-label="minting limit" class="uk-text-right">
              <!-- Vault minting limit -->
              {{
                vault.collateralAsEth.gt(0) && vault.ratios.minting.gt(0)
                  ? formatEther(
                      vault.collateralAsEth
                        .mul(oneEth)
                        .div(vault.token.rate)
                        .mul(oneEth)
                        .div(vault.ratios.minting)
                    )
                  : "n/a"
              }}
            </td>

            <td data-label="available to mint" class="uk-text-right">
              <!-- Vault minting available amount -->
              {{
                vault.collateralAsEth.gt(0) && vault.ratios.minting.gt(0)
                  ? formatEther(
                      vault.collateralAsEth
                        .mul(oneEth)
                        .mul(oneEth)
                        .div(vault.token.rate)
                        .div(vault.ratios.minting)
                        .sub(vault.debt)
                    )
                  : "n/a"
              }}
            </td>

            <td data-label="MMR (minimum minting ratio)" class="uk-text-right">
              <!-- Vault MMR -->
              {{
                vault.ratios.minting.gt(0)
                  ? ethers.BigNumber.from("100")
                      .mul(oneEth)
                      .div(vault.ratios.minting) + "%"
                  : "n/a"
              }}
            </td>

            <td data-label="utilisation" class="uk-text-right">
              <!-- Vault utilisation -->
              {{
                vault.ratios.minting.gt(0)
                  ? formatEther(
                      vault.debt
                        .mul(oneEth)
                        .mul("100")
                        .div(
                          vault.collateralAsEth
                            .mul(oneEth)
                            .div(vault.token.rate)
                            .mul(oneEth)
                            .div(vault.ratios.minting)
                        ),
                      0
                    ) + "%"
                  : "n/a"
              }}
            </td>

            <!-- Collateral columns -->
            <td
              data-label="collateral value"
              class="uk-text-right hfi-table-border-left"
            >
              <!-- Collateral full value (AUD) -->
              {{ formatEther(vault.collateralValue) }}
            </td>

            <td
              data-label="current CR (collateral ratio)"
              :class="`
                uk-text-right${
                  vault.status ? ' hfi-' + vault.status + '-cell' : ''
                }`"
            >
              <!-- Current CR -->
              {{
                vault.ratios.current.eq(0)
                  ? "n/a"
                  : formatEther(vault.ratios.current.mul(100), 0) + "%"
              }}
            </td>

            <td
              data-label="minimum CR (collateral ratio)"
              class="uk-text-right"
            >
              <!-- Minting CR -->
              {{
                vault.ratios.minting.eq(0)
                  ? "n/a"
                  : formatEther(vault.ratios.minting.mul(100), 0) + "%"
              }}
            </td>

            <td data-label="interest rate" class="uk-text-right">
              <!-- Interest rate -->
              {{
                isNaN(vault.interestRate)
                  ? "n/a"
                  : vault.interestRate.toFixed(2) + "%"
              }}
            </td>

            <td data-label="liquidation fee" class="uk-text-right">
              <!-- Liquidation Fee -->
              {{
                !vault.liquidationFee || isNaN(vault.liquidationFee)
                  ? "n/a"
                  : vault.liquidationFee.toFixed(2) + "%"
              }}
            </td>

            <td
              data-label="liquidation ratio"
              :class="`
                uk-text-right${
                  vault.status === 'danger' ? ' hfi-danger-cell' : ''
                }`"
            >
              <!-- Liquiditation ratio -->
              {{
                vault.ratios.liquidation.gt(0)
                  ? `${(
                      parseFloat(formatEther(vault.ratios.liquidation)) * 100
                    ).toLocaleString(undefined, digits(0, 2))}%`
                  : "n/a"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      :class="
        'vault' +
        (displayVaults[tokenIx] && displayVaults[tokenIx].attention
          ? ' hfi-vault-warning'
          : '')
      "
      v-if="
        !loading && displayVaults[tokenIx] && showCollateralDetails === token
      "
      ref="vault"
    >
      <div
        class="hfi-vaults-table-header uk-flex uk-flex-between uk-flex-middle"
      >
        <h4 class="uk-margin-remove-bottom">
          <span>
            <img
              class="hfi-margin-top-n2"
              width="22"
              :src="`${token}Logo.png`"
            />
            {{ token }}
            <a
              class="hfi-vaults-icon-button"
              :href="displayVaults[tokenIx].explorerMeta.typeUrl"
              target="_blank"
              :uk-tooltip="`title: ${displayVaults[tokenIx].explorerMeta.typeMessage}; pos: right;`"
            >
              <i class="fal fa-external-link-square"></i>
            </a>
          </span>
          <span
            class="cursor-pointer"
            :uk-tooltip="
              'title: shows collateral details for the ' +
              token +
              ' vault; pos: right'
            "
          >
            vault collateral
          </span>
        </h4>

        <span>
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: deposit collateral to a current/new fxToken vault; pos: right;"
            @click="openBorrowModal"
          >
            borrow
          </a>
          <a
            class="uk-button uk-button-xsmall hfi-vaults-manage-button"
            uk-tooltip="title: deposit collateral to a current/new fxToken vault; pos: right;"
            @click="openRepayModal"
          >
            repay
          </a>
          <a
            :class="`uk-button uk-button-xsmall hfi-vaults-manage-button ${
              vaultUnderwaterStatus ? 'hfi-' + vaultUnderwaterStatus : ''
            }`"
            :uk-tooltip="`title: deposit collateral to this fxToken vault${
              vaultUnderwaterStatus
                ? ', it is at risk of being redeemed.; cls: uk-active hfi-' +
                  vaultUnderwaterStatus
                : ''
            }; pos: right;`"
            @click="openDepositCollateral"
          >
            deposit collateral
          </a>
          <a
            :disabled="!!vaultUnderwaterStatus"
            :class="`uk-button uk-button-xsmall hfi-vaults-manage-button ${
              vaultUnderwaterStatus ? 'uk-disabled' : ''
            }`"
            uk-tooltip="title: withdraw collateral from this fxToken vault; pos: right;"
            @click="openWithdrawCollateral"
          >
            withdraw collateral
          </a>
        </span>
      </div>

      <table
        class="uk-table uk-table-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
      >
        <thead>
          <tr>
            <td scope="row" class="uk-text-bold hfi-subtable-border">asset</td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border">
              <span
                class="cursor-pointer"
                uk-tooltip="title: amount of collateral already deposited for minting; pos: right"
              >
                amount
              </span>
            </td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border">
              <span
                class="cursor-pointer"
                uk-tooltip="title: value of deposited collateral in vault currency; pos: right"
              >
                value
                {{ "(" + token.slice(-3) + ")" }}
              </span>
            </td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border">
              <span
                class="cursor-pointer"
                uk-tooltip="title: minimum collateral ratio - minimum collateral value required as a percentage of minted value; pos: right"
              >
                min. CR
              </span>
            </td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border">
              <span
                class="cursor-pointer"
                uk-tooltip="title: collateral specific interest rate; pos: right"
              >
                int. rate
              </span>
            </td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border">
              <span
                class="cursor-pointer"
                uk-tooltip="title: collateral specific liquidation fee; pos: right"
              >
                liq. fee
              </span>
            </td>

            <td class="uk-text-bold uk-text-right hfi-subtable-border"></td>
          </tr>
        </thead>

        <tbody :key="fxTokenKey" v-if="!loading">
          <tr
            v-for="(collateral, ix) in displayVaults[
              tokenIx
            ].collateral.filter((collateral) => collateral.amount.gt(0))"
            :key="fxTokenKey + '-' + ix"
          >
            <td
              scope="row"
              data-label="asset"
              class="uk-text-nowrap hfi-collateral-cell"
            >
              <a
                class="hfi-vaults-icon-button"
                :href="
                  collateral.explorerMeta
                    ? collateral.explorerMeta.typeUrl
                    : '#'
                "
                target="_blank"
                :uk-tooltip="`title: ${
                  collateral.explorerMeta
                    ? collateral.explorerMeta.typeMessage
                    : '#'
                }; pos: right;`"
              >
                <img
                  class="hfi-margin-top-n2"
                  width="22"
                  :src="
                    collateral.token.symbol === 'FOREX'
                      ? 'FOREXLogoLightBorder.svg'
                      : tokenList.find(
                          (tok) => tok.symbol === collateral.token.symbol
                        )
                      ? tokenList.find(
                          (tok) => tok.symbol === collateral.token.symbol
                        ).icon
                      : 'handle.fiTokenPlaceholder.png'
                  "
                />

                {{ collateral.token.symbol }}

                <i class="fal fa-external-link-square"></i>
              </a>
            </td>

            <td data-label="amount" class="uk-text-right">
              <!-- Collateral balance -->
              {{ formatEther(collateral.amount, 4, collateral.token.decimals) }}
            </td>

            <td data-label="value" class="uk-text-right">
              <!-- Collateral value (AUD) -->
              {{ formatEther(collateral.value, 2, collateral.token.decimals) }}
            </td>

            <td
              data-label="minimum CR (collateral ratio)"
              class="uk-text-right"
            >
              <!-- Collateral minting ratio -->
              {{ collateral.token.mintCollateralRatio }}%
            </td>

            <td data-label="interest rate" class="uk-text-right">
              <!-- Collateral interest rate -->
              {{
                isNaN(collateral.token.interestRate)
                  ? "n/a"
                  : formatEther(collateral.token.interestRate, 1, 1) + "%"
              }}
            </td>

            <td data-label="liquidation fee" class="uk-text-right">
              <!-- Collateral liquidation fee -->
              {{
                collateral.token.liquidationFee.gt(0)
                  ? `${(
                      parseFloat(collateral.token.liquidationFee) / 100
                    ).toLocaleString(undefined, digits(2, 2))}%`
                  : "n/a"
              }}
            </td>

            <td class="uk-text-right hfi-empty-cell"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Collateral management -->
    <DepositCollateralModal
      v-if="
        network === Network.arbitrum &&
        !loading &&
        token !== '' &&
        vaults.length > 0
      "
      :closeDepositCollateral="closeDepositCollateral"
      :setFxToken="setFxToken"
      :setDepositCollateralToken="setDepositCollateralToken"
      :token="token"
      :availableDepositCollaterals="availableDepositCollaterals"
      :depositCollateral="depositCollateral"
      :resetState="resetState"
    />

    <WithdrawCollateralModal
      v-if="
        network === Network.arbitrum &&
        !loading &&
        token !== '' &&
        vaults.length > 0
      "
      :closeWithdrawCollateral="closeWithdrawCollateral"
      :setFxToken="setFxToken"
      :setWithdrawCollateralToken="setWithdrawCollateralToken"
      :token="token"
      :availableWithdrawCollateralTokens="
        displayVaults.find((vault) => vault.token.symbol === token)
          .availableWithdrawCollaterals
      "
      :withdrawCollateral="withdrawCollateral"
      :resetState="resetState"
    />

    <div
      v-if="
        network === Network.arbitrum &&
        !loading &&
        token !== '' &&
        vaults.length > 0
      "
      id="borrow-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <BorrowComponent :modal="true" />
      </div>
    </div>

    <div
      v-if="
        network === Network.arbitrum &&
        !loading &&
        token !== '' &&
        vaults.length > 0
      "
      id="repay-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <RepayComponent :modal="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import { getDecimalsAmount, getExplorerMeta } from "../utils/utils";
import { signer } from "@/utils/wallet";
import { closeAllNotifications, formatPrice } from "@/utils/utils";
import Token from "@/types/Token";
import UIkit from "uikit";
import CollateralTokens from "@/types/CollateralTokens";
import DepositCollateralModal from "@/components/DepositCollateralModal";
import WithdrawCollateralModal from "@/components/WithdrawCollateralModal";
import BorrowComponent from "@/components/BorrowComponent";
import RepayComponent from "@/components/RepayComponent";
import Network from "@/types/Network";

export default {
  name: "Vaults",
  components: {
    DepositCollateralModal,
    WithdrawCollateralModal,
    BorrowComponent,
    RepayComponent,
  },

  data() {
    return {
      loading: true,
      ethBalance: 0,
      ethValue: 0,
      /** fxToken alias => balance */
      balances: {},
      /** fxAUD quote in Ether */
      audEthRate: 0,
      showDepositModal: false,
      showWithdrawModal: false,
      fxTokenKey: 0,
      oneEth: ethers.utils.parseEther("1"),
      /** Token currently selected for management (e.g. deposit/withdrawal) */
      token: "",
      /** show vault details */
      showCollateralDetails: null,
      tokenIx: 0,
      /** Collateral currently selected for management */
      depositCollateral: Token.ETH,
      withdrawCollateral: Token.WETH,
      ethers,
      Token,
      formatPrice,
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
      availableCollaterals: [],
      availableDepositCollaterals: [],
      vaultsStatus: null,
      withdrawAsEth: false,
      displayVaults: [],
      Network,
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    vaults() {
      return this.sdk?.vaults || [];
    },
    vault() {
      return this.vaults.find((x) => x.token.symbol === this.token);
    },
    vaultUnderwaterStatus() {
      if (!this.account || this.vaults.length === 0) return null;
      return this.displayVaults.find(
        (vault) => vault.token.symbol === this.token
      ).status;
    },
    tokenList() {
      return store.state.tokenList;
    },
  },

  watch: {
    async network() {
      this.loading = true;
      this.vaultsStatus = null;
      store.commit("setVaultsStatus", null);
    },

    async account(val) {
      if (val) {
        this.loading = true;
        this.vaultsStatus = null;
        store.commit("setVaultsStatus", null);
      } else await this.resetState();
    },

    async sdk() {
      if (this.vaults.length === 0) {
        this.loading = true;
        this.vaultsStatus = null;
        store.commit("setVaultsStatus", null);
      }
      await this.resetState();
    },
  },

  async mounted() {
    await this.resetState();
  },

  beforeDestroy() {
    closeAllNotifications();
  },

  methods: {
    async resetState() {
      this.loading = true;
      this.balances = {};
      this.ethBalance = 0;
      this.ethValue = 0;
      this.displayVaults = [];
      this.showCollateralDetails = null;
      this.fxTokenKey = 0;

      this.vaultsStatus = null;
      store.commit("setVaultsStatus", null);

      if (this.account && this.vaults.length > 0) await this.showVaults();

      closeAllNotifications();
      this.$emit("loaded", true);
      this.loading = false;
    },

    async showVaults() {
      try {
        await Promise.all([this.getAvailableCollaterals()]);

        for (let vault of this.vaults) {
          const ethTokenRate = vault.token.rate;
          // TODO -- not modify the vault object like this.
          vault.address = this.sdk.contracts[vault.token.symbol].address;
          vault.explorerMeta = await getExplorerMeta(vault.address, "token");
          vault.collateralValue = vault.collateralAsEth
            .mul(this.oneEth)
            .div(ethTokenRate);
          vault.status = null;
          vault.status =
            vault.ratios.current.gt(0) &&
            vault.ratios.current.lt(vault.ratios.minting)
              ? "warning"
              : null;
          vault.status =
            vault.ratios.current.gt(0) &&
            vault.ratios.current.lt(vault.ratios.liquidation)
              ? "danger"
              : vault.status;

          if (vault.status) {
            if (
              (!this.vaultsStatus && vault.status === "warning") ||
              (this.vaultsStatus === "warning" && vault.status === "danger")
            ) {
              this.vaultsStatus = vault.status;
              store.commit("setVaultsStatus", this.vaultStatus);
            }
          }

          const interestRates = [];
          const liquidationFees = [];
          for (let collateral of vault.collateral) {
            // TODO -- not modify these objects dynamically.
            collateral.explorerMeta = await getExplorerMeta(
              collateral.token.address,
              "token"
            );
            collateral.value = collateral.amount
              .mul(collateral.token.rate)
              .div(ethTokenRate);

            if (collateral.value.gt(0)) {
              interestRates.push({
                value: parseFloat(
                  ethers.utils.formatUnits(collateral.token.interestRate, 1)
                ),
                weight: parseFloat(
                  ethers.utils.formatUnits(
                    collateral.value,
                    collateral.token.decimals
                  )
                ),
              });
              liquidationFees.push({
                value: parseFloat(
                  ethers.utils.formatUnits(collateral.token.liquidationFee, 2)
                ),
                weight: parseFloat(
                  ethers.utils.formatUnits(
                    collateral.value,
                    collateral.token.decimals
                  )
                ),
              });
            }
          }

          vault.interestRate =
            interestRates.length === 0
              ? 0
              : await this.weightedMean(interestRates);

          vault.liquidationFee =
            liquidationFees.length === 0
              ? 0
              : await this.weightedMean(liquidationFees);
        }
        this.displayVaults = this.vaults;
        store.commit("setVaultsStatus", this.vaultsStatus);

        if (
          this.displayVaults.length > 0 &&
          this.displayVaults.findIndex(
            (vault) => vault.token.symbol === this.token
          ) < 0
        )
          this.token = this.displayVaults.filter(
            (vault) => vault.token.symbol
          )[0].token.symbol;
        else this.token = Token.fxAUD;

        // Load ETH rate in AUD.
        this.audEthRate = this.sdk.protocol.getFxTokenBySymbol(
          Token.fxAUD
        ).rate;

        // Load balances.
        for (let vault of this.displayVaults) {
          this.balances[vault.token.symbol] = await this.sdk.contracts[
            vault.token.symbol
          ].balanceOf(this.account);
        }

        if (this.availableCollaterals.length > 0) {
          for (let collateral of this.availableCollaterals) {
            this.balances[collateral] = await this.sdk.contracts[
              collateral
            ].balanceOf(this.account);
          }
        }

        for (let vault of this.displayVaults) {
          vault.availableWithdrawCollaterals = [];
          if (vault.freeCollateralAsEth.gt(0)) {
            for (let collateral of vault.collateral) {
              if (collateral.amount.gt(0))
                vault.availableWithdrawCollaterals.push(
                  collateral.token.symbol
                );
            }
          }
        }

        this.ethBalance = this.balances.ETH = await signer.getBalance();
        this.ethPrice = this.oneEth.div(this.audEthRate);
        this.ethValue = this.ethBalance.mul(this.ethPrice);

        this.availableDepositCollaterals = [Token.ETH];
        const availableDepositCollaterals = this.availableCollaterals.filter(
          (c) => c !== Token.FOREX
        );
        this.availableDepositCollaterals = [
          ...this.availableDepositCollaterals,
          ...availableDepositCollaterals,
        ];
      } catch (e) {
        console.log("Get vaults error", e);
      }
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },

    async openDepositCollateral() {
      this.showDepositModal = true;
      if (
        this.displayVaults
          .find((vault) => vault.token.symbol === this.token)
          .debt.gt(0)
      )
        this.showCollateralDetails = this.token;
      this.depositAmount = ethers.BigNumber.from(0);
      const defaultDepositCollateralToken = this.availableDepositCollaterals.includes(
        Token.ETH
      )
        ? Token.ETH
        : this.availableDepositCollaterals[0];
      await this.setDepositCollateralToken(defaultDepositCollateralToken);
      this.fxTokenKey += 1;
      closeAllNotifications();
      UIkit.modal("#deposit-collateral-modal").hide();
      UIkit.modal("#deposit-collateral-modal").show();
    },

    async closeDepositCollateral() {
      this.showDepositModal = false;
      UIkit.modal("#deposit-collateral-modal").hide();
    },

    async openWithdrawCollateral() {
      this.showWithdrawModal = true;
      const withdrawCollateralTokens = this.displayVaults.find(
        (vault) => vault.token.symbol === this.token
      ).availableWithdrawCollaterals;
      if (withdrawCollateralTokens.length > 0)
        this.showCollateralDetails = this.token;
      this.withdrawAmount = ethers.BigNumber.from(0);
      const defaultWithdrawCollateralToken =
        withdrawCollateralTokens.length === 0 ||
        withdrawCollateralTokens.includes(Token.WETH)
          ? Token.WETH
          : withdrawCollateralTokens[0];
      await this.setWithdrawCollateralToken(defaultWithdrawCollateralToken);
      this.fxTokenKey += 1;
      closeAllNotifications();
      UIkit.modal("#withdraw-collateral-modal").hide();
      UIkit.modal("#withdraw-collateral-modal").show();
    },

    async closeWithdrawCollateral() {
      this.showWithdrawModal = false;
      UIkit.modal("#withdraw-collateral-modal").hide();
    },

    toggleCollateralDetails(fxToken) {
      this.showCollateralDetails =
        this.showCollateralDetails === fxToken ? null : fxToken;
      this.token = fxToken;
      this.tokenIx = this.displayVaults.findIndex(
        (vault) => vault.token.symbol === this.token
      );
      this.fxTokenKey += 1;
      setTimeout(
        () =>
          this.$refs.vault?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          }),
        100
      );
    },

    openBorrowModal() {
      store.commit("setToken", { token: this.token });
      UIkit.modal("#borrow-modal").show();
    },

    openRepayModal() {
      store.commit("setToken", { token: this.token });
      UIkit.modal("#repay-modal").show();
    },

    weightedMean: (array) => {
      const result = array
        .map((set) => {
          const sum = set.value * set.weight;
          return [sum, set.weight];
        })
        .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);
      return result[0] / result[1];
    },

    getAvailableCollaterals: async function () {
      this.availableCollaterals = [];

      for (let collateral of CollateralTokens) {
        const collateralAddress = this.sdk.contracts[collateral].address;
        if (!collateralAddress || collateralAddress.length !== 42) continue;

        this.availableCollaterals.push(collateral);
      }
    },

    setFxToken: async function (option) {
      this.toggleCollateralDetails(this.token);
      this.token = option;
      this.showCollateralDetails =
        this.displayVaults.length < 1 ||
        (option !== "" &&
          this.displayVaults.find((vault) => vault.token.symbol === option)
            .collateralValue <= 0)
          ? ""
          : option;
      await this.setDepositCollateralToken(this.depositCollateral);
    },

    setDepositCollateralToken: async function (option) {
      this.depositCollateral = option;
      // Update balance.
      await this.updateCollateralBalance();
    },

    setWithdrawCollateralToken: async function (option) {
      this.withdrawAsEth = false;
      this.withdrawCollateral = option;
      // Update balance.
      await this.updateCollateralBalance();
    },

    updateCollateralBalance: async function () {
      this.collateralWalletBalance = null;
      this.collateralWalletBalance =
        this.depositCollateral !== Token.ETH
          ? await this.sdk.contracts[this.depositCollateral].balanceOf(
              this.account
            )
          : await this.sdk.signer.getBalance();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;

.hfi-vaults-container {
  max-width: calc(100vw - 30px);
}

@media (min-width: 640px) {
  .hfi-vaults-container {
    max-width: calc(100vw - 60px);
  }
}

@media (min-width: 960px) {
  .hfi-vaults-container {
    max-width: calc(100vw - 80px);
  }
}

.hfi-vaults-table-header {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green;
}

.uk-dropdown {
  z-index: 1031;
}

.uk-dropdown-nav .uk-nav-header {
  color: handle.$green !important;
  text-transform: none;
}

.uk-dropdown-nav .uk-nav-divider {
  border-top-color: handle.$green;
}

.uk-dropdown-nav > li {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.uk-dropdown-nav > li:not(.uk-active) {
  color: handle.$green;
  background-color: handle.$background;
}

.uk-dropdown-nav > li:not(.uk-nav-header):hover,
.uk-dropdown-nav > li:not(.uk-nav-header):focus,
.uk-dropdown-nav > li:not(.uk-nav-header).uk-active {
  color: handle.$background;
  background-color: handle.$green !important;
  a {
    color: inherit;
  }
}

.hfi-top-n1 {
  top: -1px;
}

.hfi-margin-top-n2 {
  margin-top: -2px;
}

.hfi-token-cell {
  width: 116px;
}

.hfi-collateral-cell {
  width: 84px;
}

.uk-table {
  border-top: unset;
  margin-top: -2px !important;
}

.fa-stack {
  margin-top: -4px;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.25em;
}

.fa-stack-halfx {
  font-size: 0.5em;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
