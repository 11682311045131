var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-flex-top uk-margin-remove-top",attrs:{"id":"deposit-collateral-modal","uk-modal":"bgClose: false; container: false;"}},[_c('form',{staticClass:"uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small",attrs:{"novalidate":""}},[_c('fieldset',{staticClass:"uk-fieldset uk-width-1-1"},[_c('a',{staticClass:"uk-modal-close-default",on:{"click":function($event){$event.preventDefault();return _vm.closeDepositCollateral($event)}}},[_c('i',{staticClass:"fal fa-times"})]),_c('h4',{staticClass:"hfi-modal-title"},[_vm._v(" "+_vm._s("deposit collateral" + (_vm.vault && _vm.vault.collateralValue && _vm.vault.collateralValue.eq(0) ? " to create new vault" : ""))+" ")]),_c('div',{staticClass:"fxTokenDepositWrapper"},[_c('SelectFxToken',{staticClass:"fxTokenDepositWrapper",attrs:{"id":"selectDepositFxToken","label":"vault","disabled":_vm.processingDeposit,"boundaryClass":"fxTokenDepositWrapper","value":_vm.token},on:{"change":function (selection) {
              _vm.setFxToken(selection);
              _vm.getMaxDepositAmount();
            }}})],1),_c('div',{staticClass:"collateralWrapper"},[_c('SelectCollateralToken',{staticClass:"depositCollateralWrapper",attrs:{"id":"selectDepositCollateral","label":"deposit collateral","options":_vm.availableDepositCollaterals,"disabled":_vm.processingDeposit,"boundaryClass":"depositCollateralWrapper","value":_vm.depositCollateral},on:{"change":function (selection) {
              _vm.setDepositCollateralToken(selection);
              _vm.getMaxDepositAmount();
            }}})],1),_c('label',{staticClass:"uk-form-label uk-flex uk-width-expand uk-flex-between",attrs:{"for":"depositAmount"}},[_c('span',[_vm._v("amount")]),_c('span',[_vm._v(" available: "+_vm._s(_vm.formatEther(_vm.maxDepositAmount, 4, _vm.collateralDecimals))+" "+_vm._s(_vm.depositCollateral)+" ")])]),_c('div',{staticClass:"uk-form-controls uk-position-relative uk-flex uk-margin-xsmall-bottom"},[_c('NumberInput',{staticClass:"uk-width-expand",attrs:{"id":"depositAmount","type":"number","placeholder":"tokens to deposit","value":_vm.depositAmount,"decimals":_vm.collateralDecimals,"min":_vm.ethers.BigNumber.from(0),"step":0.001,"alert":!_vm.hasEnoughBalanceForDeposit,"disabled":_vm.processingDeposit || !_vm.hasEnoughBalanceForDeposit},on:{"input":_vm.setDepositAmount}}),_c('button',{staticClass:"uk-button hfi-button hfi-input-button",attrs:{"disabled":_vm.processingDeposit || !_vm.hasEnoughBalanceForDeposit},on:{"click":function($event){$event.preventDefault();return _vm.setMaxDepositAmount($event)}}},[_vm._v(" max ")])],1),_c('div',[_c('button',{staticClass:"uk-button uk-button-primary uk-width-expand hfi-button uk-margin-small-top",attrs:{"disabled":_vm.processingDeposit ||
            _vm.depositAmount.eq(0) ||
            !_vm.hasEnoughBalanceForDeposit},on:{"click":function($event){$event.preventDefault();return _vm.deposit($event)}}},[_vm._v(" "+_vm._s(_vm.depositButtonText)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }