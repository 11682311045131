<template>
  <div
    id="withdraw-collateral-modal"
    class="uk-flex-top uk-margin-remove-top"
    uk-modal="bgClose: false; container: false;"
  >
    <form
      novalidate
      class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <a
          @click.prevent="closeWithdrawCollateral"
          class="uk-modal-close-default"
        >
          <i class="fal fa-times"></i>
        </a>

        <h4 class="hfi-modal-title">withdraw collateral</h4>

        <div class="fxTokenWithdrawWrapper">
          <SelectFxToken
            id="selectWithdrawFxToken"
            label="vault"
            @change="
              (selection) => {
                setFxToken(selection);
                getMaxWithdrawAmount();
              }
            "
            :disabled="processingWithdrawal"
            class="fxTokenWithdrawWrapper"
            boundaryClass="fxTokenWithdrawWrapper"
            :value="token"
          />
        </div>

        <div class="withdrawCollateralWrapper">
          <SelectCollateralToken
            id="selectWithdrawCollateral"
            label="withdraw collateral"
            :options="availableWithdrawCollaterals"
            @change="
              (selection) => {
                setWithdrawCollateralToken(selection);
                getMaxWithdrawAmount();
              }
            "
            :disabled="processingWithdrawal"
            class="withdrawCollateralWrapper"
            boundaryClass="withdrawCollateralWrapper"
            :value="withdrawCollateral"
            :showETHForWETH="false"
          />
        </div>

        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between uk-margin-small-top"
          for="withdrawAmount"
        >
          <span>amount</span>
          <span>
            available:
            {{ formatEther(maxWithdrawAmount, 4, collateralDecimals) }}
            {{ withdrawCollateral }}
          </span>
        </label>

        <div
          class="uk-form-controls uk-position-relative uk-flex uk-margin-xsmall-bottom"
        >
          <NumberInput
            class="uk-width-expand"
            id="withdrawAmount"
            type="number"
            placeholder="amount to withdraw"
            :value="withdrawAmount"
            :decimals="collateralDecimals"
            :min="ethers.BigNumber.from(0)"
            :step="0.001"
            :alert="!hasEnoughBalanceForWithdrawal"
            @input="setWithdrawAmount"
            :disabled="processingWithdrawal || !hasEnoughBalanceForWithdrawal"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click.prevent="setMaxWithdrawAmount"
            :disabled="processingWithdrawal || !hasEnoughBalanceForWithdrawal"
          >
            max
          </button>
        </div>
        <div>
          <button
            class="uk-button uk-button-primary uk-width-expand hfi-button uk-margin-small-top"
            :disabled="
              processingWithdrawal ||
              withdrawAmount.eq(0) ||
              !hasEnoughBalanceForWithdrawal
            "
            @click.prevent="withdraw"
          >
            {{ withdrawButtonText }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Token from "@/types/Token";
import { ethers } from "ethers";
import sendTransaction from "@/contracts/utils/sendTransaction";
import { store } from "@/store";
import {
  showNotification,
  closeAllNotifications,
  getDecimalsAmount,
} from "@/utils/utils";
import SelectFxToken from "@/components/SelectFxToken";
import SelectCollateralToken from "@/components/SelectCollateralToken";
import NumberInput from "@/components/NumberInput";
import calculateMaxWithdrawAmount from "@/contracts/utils/calculateMaxWithdrawAmount";

export default {
  name: "WithdrawCollateralModal",
  components: {
    NumberInput,
    SelectFxToken,
    SelectCollateralToken,
  },
  props: {
    closeWithdrawCollateral: { type: Function },
    setFxToken: { type: Function },
    setWithdrawCollateralToken: { type: Function },
    token: { type: String },
    availableWithdrawCollaterals: { type: Array },
    withdrawCollateral: { type: String },
    resetState: { type: Function },
  },
  data() {
    return {
      processingWithdrawal: false,
      withdrawAmount: ethers.BigNumber.from(0),
      overrideGasLimit: ethers.BigNumber.from(0),
      overrideGasPrice: ethers.BigNumber.from(0),
      maxWithdrawAmount: ethers.BigNumber.from(0),
      collateralWalletBalance: ethers.BigNumber.from(0),
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
      Token,
      ethers,
    };
  },
  computed: {
    sdk() {
      return store.state.refHandleSDK.get();
    },

    vaults() {
      return this.sdk?.signer
        ? this.sdk?.vaults.sort((a, b) =>
            a.token.symbol > b.token.symbol ? 1 : -1
          )
        : [];
    },

    vault() {
      return this.vaults.find((x) => x.token.symbol === this.token);
    },

    fxTokensWithAvailableCollateral() {
      return this.vaults
        .filter((vault) => vault.collateralAsEth.gt(0))
        .map((vault) => vault.token.symbol)
        .sort((a, b) => (a > b ? 1 : -1));
    },

    withdrawButtonText() {
      return this.processingWithdrawal
        ? "processing..."
        : !this.hasEnoughBalanceForWithdrawal
        ? "insufficient funds"
        : "withdraw";
    },

    hasEnoughBalanceForWithdrawal() {
      return (
        this.maxWithdrawAmount.gt(0) &&
        this.withdrawAmount.lte(this.maxWithdrawAmount)
      );
    },

    collateralDecimals() {
      const result = this.vault.collateral.find(
        (x) => x.token.symbol === this.withdrawCollateral
      );
      return result ? result.token.decimals : 18;
    },
  },

  async mounted() {
    await this.getMaxWithdrawAmount();
  },

  methods: {
    setWithdrawAmount(value) {
      this.withdrawAmount = value;
    },

    async getMaxWithdrawAmount() {
      const collateral = this.vault.collateral.find(
        (x) => x.token.symbol === this.withdrawCollateral
      );
      if (!collateral) {
        this.maxWithdrawAmount = ethers.constants.Zero;
        return;
      }
      const collateralUnit = ethers.constants.One.mul(10).pow(
        collateral.token.decimals
      );

      const collateralBalanceEth = collateral.amount
        .mul(collateral.token.rate)
        .div(collateralUnit);
      const maxEth = calculateMaxWithdrawAmount(
        collateralBalanceEth,
        collateral.token.rate,
        this.vault.collateralAsEth,
        this.vault.ratios.minting.add(1),
        collateral.token.mintCollateralRatio,
        this.vault.debtAsEth
      );
      this.maxWithdrawAmount = getDecimalsAmount(
        maxEth,
        18,
        collateral.token.decimals
      );
    },

    async setMaxWithdrawAmount() {
      await this.setWithdrawAmount(this.maxWithdrawAmount);
    },

    async withdraw() {
      this.processingWithdrawal = true;
      try {
        const tx = await this.vault.withdrawCollateral(
          this.withdrawAmount,
          this.withdrawCollateral,
          true
        );

        await sendTransaction(
          tx,
          `follow wallet instructions to confirm your withdrawal of ${this.formatEther(
            this.withdrawAmount,
            4,
            this.collateralDecimals
          )} ${this.withdrawCollateral} to your ${this.token} vault`,
          "collateral withdrawal processing, please wait...",
          async (_t, explorerMessage) => {
            return `withdrawal of ${this.formatEther(
              this.withdrawAmount,
              4,
              this.collateralDecimals
            )} ${this.withdrawCollateral} to your ${
              this.token
            } vault successful. ${explorerMessage}`;
          }
        );

        this.closeWithdrawCollateral();
        await this.resetState();
      } catch (error) {
        this.notifyError(error);
      } finally {
        this.processingWithdrawal = false;
      }
    },

    notifyError(error) {
      closeAllNotifications();
      console.error(error);
      const errorMessage = error.data?.message ?? error.message;
      console.log(errorMessage);
      showNotification(
        "error",
        errorMessage == "withdrawal failed: " || errorMessage.length == 0
          ? "something went wrong"
          : errorMessage
      );
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
