<template>
  <div
    id="deposit-collateral-modal"
    class="uk-flex-top uk-margin-remove-top"
    uk-modal="bgClose: false; container: false;"
  >
    <form
      novalidate
      class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <a
          @click.prevent="closeDepositCollateral"
          class="uk-modal-close-default"
        >
          <i class="fal fa-times"></i>
        </a>

        <h4 class="hfi-modal-title">
          {{
            "deposit collateral" +
            (vault && vault.collateralValue && vault.collateralValue.eq(0)
              ? " to create new vault"
              : "")
          }}
        </h4>

        <div class="fxTokenDepositWrapper">
          <SelectFxToken
            id="selectDepositFxToken"
            label="vault"
            @change="
              (selection) => {
                setFxToken(selection);
                getMaxDepositAmount();
              }
            "
            :disabled="processingDeposit"
            class="fxTokenDepositWrapper"
            boundaryClass="fxTokenDepositWrapper"
            :value="token"
          />
        </div>

        <div class="collateralWrapper">
          <SelectCollateralToken
            id="selectDepositCollateral"
            label="deposit collateral"
            :options="availableDepositCollaterals"
            @change="
              (selection) => {
                setDepositCollateralToken(selection);
                getMaxDepositAmount();
              }
            "
            :disabled="processingDeposit"
            class="depositCollateralWrapper"
            boundaryClass="depositCollateralWrapper"
            :value="depositCollateral"
          />
        </div>

        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="depositAmount"
        >
          <span>amount</span>
          <span>
            available:
            {{ formatEther(maxDepositAmount, 4, collateralDecimals) }}
            {{ depositCollateral }}
          </span>
        </label>

        <div
          class="uk-form-controls uk-position-relative uk-flex uk-margin-xsmall-bottom"
        >
          <NumberInput
            class="uk-width-expand"
            id="depositAmount"
            type="number"
            placeholder="tokens to deposit"
            :value="depositAmount"
            :decimals="collateralDecimals"
            :min="ethers.BigNumber.from(0)"
            :step="0.001"
            :alert="!hasEnoughBalanceForDeposit"
            @input="setDepositAmount"
            :disabled="processingDeposit || !hasEnoughBalanceForDeposit"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click.prevent="setMaxDepositAmount"
            :disabled="processingDeposit || !hasEnoughBalanceForDeposit"
          >
            max
          </button>
        </div>
        <div>
          <button
            class="uk-button uk-button-primary uk-width-expand hfi-button uk-margin-small-top"
            :disabled="
              processingDeposit ||
              depositAmount.eq(0) ||
              !hasEnoughBalanceForDeposit
            "
            @click.prevent="deposit"
          >
            {{ depositButtonText }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Token from "@/types/Token";
import { ethers } from "ethers";
import sendTransaction from "@/contracts/utils/sendTransaction";
import { store } from "@/store";
import {
  showNotification,
  closeAllNotifications,
  getDecimalsAmount,
} from "@/utils/utils";
import SelectFxToken from "@/components/SelectFxToken";
import SelectCollateralToken from "@/components/SelectCollateralToken";
import NumberInput from "@/components/NumberInput";
import { getReferral } from "@/utils/url";

export default {
  name: "DepositCollateralModal",
  components: {
    NumberInput,
    SelectFxToken,
    SelectCollateralToken,
  },
  props: {
    closeDepositCollateral: { type: Function },
    setFxToken: { type: Function },
    setDepositCollateralToken: { type: Function },
    token: { type: String },
    availableDepositCollaterals: { type: Array },
    depositCollateral: { type: String },
    resetState: { type: Function },
  },
  data() {
    return {
      processingDeposit: false,
      depositAmount: ethers.BigNumber.from(0),
      overrideGasLimit: ethers.BigNumber.from(0),
      overrideGasPrice: ethers.BigNumber.from(0),
      maxDepositAmount: ethers.BigNumber.from(0),
      collateralWalletBalance: ethers.BigNumber.from(0),
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
      Token,
      ethers,
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },

    vaults() {
      return this.sdk?.signer ? this.sdk?.vaults : [];
    },

    vault() {
      return this.vaults.find((x) => x.token.symbol === this.token);
    },

    depositButtonText() {
      return this.processingDeposit
        ? "processing..."
        : !this.hasEnoughBalanceForDeposit
        ? "insufficient funds"
        : "deposit";
    },

    hasEnoughBalanceForDeposit() {
      return (
        this.maxDepositAmount.gt(0) &&
        this.depositAmount.lte(this.maxDepositAmount)
      );
    },

    collateralDecimals() {
      const result = this.vault.collateral.find(
        (x) => x.token.symbol === this.depositCollateral
      );
      return result ? result.token.decimals : 18;
    },
  },

  async mounted() {
    await this.getMaxDepositAmount();
  },

  methods: {
    setDepositAmount(value) {
      this.depositAmount = value;
    },

    async getMaxDepositAmount() {
      if (this.depositCollateral !== Token.ETH) {
        this.overrideGasLimit = ethers.BigNumber.from(0);
        this.overrideGasPrice = ethers.BigNumber.from(0);
        this.maxDepositAmount = await this.sdk.contracts[
          this.depositCollateral
        ].balanceOf(this.account);
        return;
      }

      // Set max token deposit amount.
      const gasPrice = await this.sdk.provider.getGasPrice();
      const gasLimit = ethers.BigNumber.from("5000000");
      const gasCost = gasLimit.mul(gasPrice);
      this.overrideGasLimit = gasLimit;
      this.overrideGasPrice = gasPrice;
      const balance = await this.sdk.signer.getBalance();
      this.maxDepositAmount = balance.lte(gasCost)
        ? ethers.BigNumber.from(0)
        : balance.sub(gasCost);
    },

    async setMaxDepositAmount() {
      await this.setDepositAmount(this.maxDepositAmount);
    },

    async deposit() {
      this.processingDeposit = true;
      try {
        const tx = await this.vault.depositCollateral(
          this.depositAmount,
          this.depositCollateral,
          true,
          this.overrideGasLimit.gt(0)
            ? this.overrideGasLimit
            : ethers.BigNumber.from("6000000"),
          this.overrideGasPrice.gt(0) ? this.overrideGasPrice : null,
          getReferral()
        );

        await sendTransaction(
          tx,
          `follow wallet instructions to confirm your deposit of ${this.formatEther(
            this.depositAmount,
            4,
            this.collateralDecimals
          )} ${this.depositCollateral} to your ${this.token} vault`,
          "collateral deposit processing, please wait...",
          async (_t, explorerMessage) => {
            return `deposit of ${this.formatEther(
              this.depositAmount,
              4,
              this.collateralDecimals
            )} ${this.depositCollateral} to your ${
              this.token
            } vault successful. ${explorerMessage}`;
          }
        );

        this.closeDepositCollateral();
        await this.resetState();
      } catch (error) {
        this.notifyError(error);
      } finally {
        this.processingDeposit = false;
      }
    },

    notifyError(error) {
      closeAllNotifications();
      console.error(error);
      const errorMessage = error.data?.message ?? error.message;
      console.log(errorMessage);
      showNotification(
        "error",
        errorMessage == "deposit failed: " || errorMessage.length == 0
          ? "something went wrong"
          : errorMessage
      );
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
