<template>
  <div class="repay">
    <h2 :class="`${modal ? 'uk-h4 hfi-modal-title' : 'uk-h2'}`">repay</h2>

    <form
      novalidate
      autocomplete="off"
      class="uk-form-width-large uk-margin-small-top"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <SelectFxToken
          v-on:change="(selection) => setFxToken(selection)"
          :disabled="wrongNetwork || !account || processingTransaction"
          :value="token"
        />

        <div class="uk-margin">
          <label
            class="uk-form-label uk-flex uk-width-expand uk-flex-between"
            for="repayAmount"
          >
            <span>amount</span>
            <span v-if="tokenBalance && vault && vault.debt">
              <span> bal: {{ formatEther(tokenBalance) }}, </span>
              <span> debt: {{ formatEther(vault.debt, 2) }} {{ token }} </span>
            </span>
          </label>
          <div class="uk-form-controls uk-position-relative uk-flex">
            <NumberInput
              class="uk-width-expand"
              id="repayAmount"
              type="number"
              placeholder="fxTokens to repay"
              :alert="
                !hasEnoughBalance || !hasEnoughDebt || !meetsMinimumFxMintAmount
              "
              :value="amount"
              :min="ethers.BigNumber.from(0)"
              :disabled="wrongNetwork || !account || processingTransaction"
              @change="onSetAmount"
            />
            <button
              class="uk-button hfi-button hfi-input-button"
              @click="getMaxAmount"
              :disabled="wrongNetwork || !account || processingTransaction"
            >
              max
            </button>
          </div>
        </div>

        <div class="uk-margin">
          <label
            class="uk-form-labeluk-form-label uk-flex uk-flex-middle"
            for="collateralRatio"
          >
            vault collateral ratio
            <a
              class="hfi-info-button uk-margin-remove-top"
              uk-icon="icon: question; ratio: .75"
              :uk-tooltip="'title: ' + collateralTooltipText + '; pos: right'"
            />
          </label>
          <CollateralSlider
            id="collateralRatio"
            :target-ratio="toSafeNumber(this.collateralRatio) / 100"
            :min-display-ratio="toSafeNumber(this.mintingRatio()) / 125 - 0.05"
            :liquidate-ratio="toSafeNumber(this.mintingRatio()) / 125"
            :cease-rewards-ratio="toSafeNumber(this.mintingRatio()) / 100"
            :minting-ratio="toSafeNumber(this.mintingRatio()) / 100"
            :max-display-ratio="toSafeNumber(this.mintingRatio()) / 100 + 2"
            :min-slider-ratio="
              this.vault != null
                ? toSafeNumber(this.vault.ratios.current.mul(100).div(oneEth)) /
                  100
                : 0
            "
            :on-change="onRatioSliderChange"
            :is-read-only="
              wrongNetwork || !account || loadingData || processingTransaction
            "
          />
        </div>

        <div class="uk-margin-small-top">
          <label
            class="uk-form-label hfi-form-label-width-medium"
            for="repayButton"
          ></label>
          <div class="uk-form-controls">
            <button
              id="repayButton"
              class="uk-button uk-button-primary uk-width-expand hfi-button"
              @click="requestRepay"
              type="button"
              :disabled="!canTransact()"
            >
              {{ transactionButtonText() }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Token from "../types/Token";
import { store } from "@/store";
import NumberInput from "@/components/NumberInput";
import { showNotification } from "@/utils/utils";
import { addEventListener, removeEventListener } from "../utils/event";
import Event from "../types/Event";
import CollateralSlider from "../components/CollateralSlider";
import SelectFxToken from "../components/SelectFxToken";
import { closeAllNotifications } from "../utils/utils";
import { ethers } from "ethers";
import formatError from "@/utils/formatError";
import sendTransaction from "../contracts/utils/sendTransaction";

export default {
  name: "RepayComponent",
  props: {
    modal: { type: Boolean, default: false },
  },
  components: {
    SelectFxToken,
    NumberInput,
    CollateralSlider,
  },
  data() {
    return {
      amount: ethers.BigNumber.from(0),
      minimumMintingAmountEth: ethers.BigNumber.from(0),
      collateral: ethers.BigNumber.from(0),
      tokenBalance: ethers.BigNumber.from(0),
      collateralRatio: ethers.BigNumber.from(0),
      feePerMille: ethers.BigNumber.from(0),
      hasEnoughBalance: true,
      hasEnoughDebt: true,
      token: store.state.token || Token.fxAUD,
      repayButtonText: "repay",
      loadingRate: false,
      processingTransaction: false,
      loadingData: true,
      loaded: false,
      oneEth: ethers.utils.parseEther("1"),
      walletUpdateEventId: undefined,
      canTransact: () =>
        this.account &&
        this.sdk &&
        (!this.$route.meta?.networks ||
          this.$route.meta?.networks.includes(this.network)) &&
        !this.loadingRate &&
        !this.processingTransaction &&
        this.hasEnoughBalance &&
        this.hasEnoughDebt &&
        this.amount.gt(0) &&
        this.meetsMinimumFxMintAmount,
      transactionButtonText: () => {
        if (!this.account) return "please connect wallet";
        if (
          this.$route.meta?.networks &&
          !this.$route.meta?.networks.includes(this.network)
        )
          return "please change network";
        if (!this.meetsMinimumFxMintAmount)
          return "minimum borrow amount not met";
        if (!this.hasEnoughBalance) return "insufficient balance";
        if (!this.hasEnoughDebt) return "insufficient debt";
        if (this.loadingData || !this.sdk) return "loading...";
        if (this.processingTransaction) return "processing...";

        return this.repayButtonText;
      },
      Token,
      ethers,
      toSafeNumber: (value) => {
        if (!ethers.BigNumber.isBigNumber(value))
          throw new Error("Not a BigNumber");
        if (value.gt(Number.MAX_SAFE_INTEGER.toString())) return Infinity;
        if (value.lt(Number.MIN_SAFE_INTEGER.toString())) return -Infinity;
        return value.toNumber();
      },
      collateralTooltipText:
        "minimum borrowing collateralisation ratio for the fxToken vault ",
      formatEther: (value, digits = 2) =>
        parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        ),
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    minimumFxMintAMount() {
      if (this.tokenRate.eq(0)) return ethers.constants.Zero;
      const fxAmount = this.minimumMintingAmountEth
        .mul(ethers.constants.WeiPerEther)
        .div(this.tokenRate);
      // Round up to the nearest 100.
      const roundBy = ethers.utils.parseEther("100");
      return fxAmount.add(roundBy).sub(1).div(roundBy).mul(roundBy);
    },
    meetsMinimumFxMintAmount() {
      // If input amount is zero, return true as this is handled
      // separately.
      if (this.amount.eq(0)) return true;
      // User should be allowed to burn all debt.
      if (this.amount.gte(this.tokenDebt)) return true;
      const current = this.tokenDebt.gte(this.amount)
        ? this.tokenDebt.sub(this.amount)
        : ethers.constants.Zero;
      return current.gte(this.minimumFxMintAMount);
    },
    shouldBurnWithEntireUserTokenBalanceDueToMinimumMintingAmount() {
      if (this.amount.eq(0)) return false;
      const remainingDebt = this.tokenDebt.sub(this.amount);
      // If the remaining debt is less than the minimum mint amount,
      // request to burn amount equal to user's token balance,
      // so that the contract limits that value to the debt including
      // interest @ the time the block was mined at.
      return remainingDebt.lt(this.minimumFxMintAMount);
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    vault() {
      return this.sdk?.vaults.find((x) => x.token.symbol === this.token);
    },
    tokenRate() {
      return (
        this.sdk?.protocol.getFxTokenBySymbol(this.token).rate ??
        ethers.constants.Zero
      );
    },
    tokenDebt() {
      return this.vault?.debt ?? ethers.constants.Zero;
    },
    network() {
      return store.state.network;
    },
    isLite() {
      return store.state.isLite;
    },
    wrongNetwork() {
      return (
        this.$route.meta?.networks &&
        !this.$route.meta?.networks.includes(this.network)
      );
    },
    stateToken() {
      return store.state.token;
    },
  },
  beforeUpdate() {
    this.repayButtonText = store.state.account
      ? "repay"
      : "Please connect wallet";
  },
  beforeMount() {
    this.repayButtonText = store.state.account
      ? "repay"
      : "Please connect wallet";
  },
  mounted() {
    this.initialiseState();
  },
  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },
  watch: {
    async amount() {
      this.hasEnoughBalance = this.tokenBalance.gte(this.amount);
      if (this.vault) this.hasEnoughDebt = this.amount.lte(this.vault.debt);
    },
    async sdk() {
      // Load data whenever the SDK initialises.
      if (this.loaded || !this.sdk) return;
      await this.loadData();
    },
    stateToken() {
      this.setFxToken(store.state.token);
    },
  },
  methods: {
    mintingRatio() {
      return this.vault == null
        ? ethers.BigNumber.from(0)
        : this.vault.ratios.minting.mul(100).div(ethers.constants.WeiPerEther);
    },
    clearForm: function () {
      this.amount = ethers.BigNumber.from(0);
      this.collateral = ethers.BigNumber.from(0);
      this.collateralRatio = ethers.BigNumber.from(0);
    },
    initialiseState: async function () {
      this.processingTransaction = true;
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
      this.processingTransaction = false;
    },
    resetState: async function () {
      this.loadingData = true;
      this.clearForm();
      await this.loadData();
      await this.recalculateCollateralRatio();
      this.loadingData = false;
    },
    loadData: async function () {
      if (!this.account || !this.sdk) return;
      this.loaded = true;
      this.minimumMintingAmountEth = await this.sdk.contracts.comptroller.minimumMintingAmount();
      this.processingTransaction = true;
      await this.setFxToken(this.token);
      this.feePerMille = await this.sdk.contracts.handle.burnFeePerMille();
      this.processingTransaction = false;
    },
    onSetAmount: async function (value) {
      if (value != null) this.amount = value;
      await this.recalculateCollateralRatio();
    },
    getMaxAmount: async function (event) {
      event.preventDefault();
      const amount = this.vault.debt.gt(this.tokenBalance)
        ? this.tokenBalance
        : this.vault.debt;
      await this.onSetAmount(amount);
    },
    setFxToken: async function (option) {
      this.token = option;
      this.clearForm();
      this.tokenBalance = await this.sdk?.contracts[this.token].balanceOf(
        this.account
      );
      await this.recalculateCollateralRatio();
    },
    recalculateCollateralRatio: async function () {
      if (!this.account || !this.sdk) return;
      const postBurnAmount = this.vault.debt.sub(this.amount);
      if (postBurnAmount.eq(0)) {
        this.collateralRatio = ethers.BigNumber.from(0);
        return;
      }
      const collateralFee = this.amount
        .mul(this.feePerMille)
        .div("1000")
        .mul(this.tokenRate)
        .div(this.oneEth);
      this.collateralRatio = this.vault.collateralAsEth
        .sub(collateralFee)
        .mul(this.oneEth)
        .mul("100")
        .div(postBurnAmount.mul(this.tokenRate));
    },
    onRatioSliderChange: async function (newValue) {
      if (!this.vault) return;
      this.collateralRatio = ethers.BigNumber.from(Math.floor(newValue * 100));

      const newAmount = this.vault.collateralAsEth
        .mul("100")
        .div(this.collateralRatio)
        .mul(this.oneEth)
        .div(this.tokenRate);

      if (newAmount.gt(this.vault.debt)) return;
      this.amount = this.vault.debt.sub(newAmount);
    },
    requestRepay: async function () {
      this.processingTransaction = true;
      try {
        const amount = this
          .shouldBurnWithEntireUserTokenBalanceDueToMinimumMintingAmount
          ? this.tokenBalance
          : this.amount;
        const tx = await this.vault.burn(amount, true);

        await sendTransaction(
          tx,
          `follow wallet instructions to confirm your repay of ${this.formatEther(
            this.amount
          )} ${this.token}`,
          undefined,
          async (_t, etherscanMessage) => {
            this.tokenBalance = await this.sdk.contracts[this.token].balanceOf(
              this.account
            );
            return (
              `${this.token} tokens repaid successfully. your` +
              ` new balance is ${this.formatEther(this.vault.debt)}` +
              ` ${this.token}. ${etherscanMessage}`
            );
          }
        );
      } catch (error) {
        closeAllNotifications();
        console.error(error);
        this.errorMessage = error.data?.message ?? error.message;
        if (error)
          showNotification("error", (await formatError(error)).toLowerCase());
      } finally {
        await this.resetState();
        this.processingTransaction = false;
      }
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
