import { ethers } from "ethers";

const oneEth = ethers.constants.WeiPerEther;
const bn = (x) => ethers.BigNumber.from(x);

function sqrt(value) {
  if (value < 0n) throw new Error("negative value passed to sqrt");
  if (value < 2n) return value;
  const newtonIteration = (n, x0) => {
    const x1 = (n / x0 + x0) >> 1n;
    if (x0 === x1 || x0 === x1 - 1n) {
      return x0;
    }
    return newtonIteration(n, x1);
  };
  return newtonIteration(value, 1n);
}

/**
 * Calculates a withdrawal amount that results in the vault having the same CR
 * as its new minimum CR.
 * @param {ethers.BigNumber} collateralBalance
 * @param {ethers.BigNumber} collateralPrice
 * @param {ethers.BigNumber} currentCollateralAsEth
 * @param {ethers.BigNumber} currentMinRatio
 * @param {ethers.BigNumber} collateralTypeMinRatio
 * @param {ethers.BigNumber} debtEth
 * @param {boolean} roundingTolerance
 */
const calculateMaxWithdrawAmount = (
  collateralBalance,
  collateralPrice,
  currentCollateralAsEth,
  currentMinRatio,
  collateralTypeMinRatio,
  debtEth
) => {
  const minRatioPrecision = ethers.constants.WeiPerEther;
  const collateralTypeRatioPrecision = "100";

  let a = debtEth.mul(
    bn("-4")
      .mul(currentCollateralAsEth)
      .mul(collateralTypeMinRatio)
      .div(collateralTypeRatioPrecision)
      .add(
        bn("4")
          .mul(currentCollateralAsEth)
          .mul(currentMinRatio)
          .div(minRatioPrecision)
      )
      .add(
        debtEth
          .mul(collateralTypeMinRatio.mul(collateralTypeMinRatio))
          .div(collateralTypeRatioPrecision)
          .div(collateralTypeRatioPrecision)
      )
  );
  // Use js' BigInt to calculate sqrt since ethers.BigNumber does not support it.
  if (a.lt(0)) a = a.mul("-1");
  const aBN = sqrt(BigInt(a.toString())).toString();
  const aSqrt = ethers.BigNumber.from(aBN.toString());
  const withdrawEth = aSqrt
    .div("-2")
    .add(currentCollateralAsEth)
    .sub(
      debtEth
        .mul(collateralTypeMinRatio)
        .div(collateralTypeRatioPrecision)
        .div("2")
    );
  const max = withdrawEth.mul(oneEth).div(collateralPrice);
  const maxBalance = collateralBalance.mul(oneEth).div(collateralPrice);
  const result = max.gt(maxBalance) ? maxBalance : max;
  return result.gt(0) ? result : ethers.BigNumber.from(0);
};

export default calculateMaxWithdrawAmount;
